/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import TextTitleH1 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Title.H1';
import TextTitleH3 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Title.H3';
import TextBody2 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Body2';
import LinkMail from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Link.Mail';
import ParagonPoweredBy from '../PoweredByParagonOne';
import styles from './styles.module.scss';

const OneOnOne404 = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport('42_8', propsArg, vars, variants);

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_42_5, props.className])}
      ref={ref}
      key={getKey(report, '42_5')}
    >
      <Box tag="div" className={styles.clt_57_50} key={getKey(report, '57_50')}>
        <TextTitleH1
          text="404"
          style={{
            fontWeight: 700,
            fontSize: '32px',
            lineHeight: '36px',
            letterSpacing: '-0.03',
            fontFamily: 'Inter',
          }}
          key={getKey(report, '40_65')}
          data-d={getKey(report, '40_65')}
        />
        <TextTitleH3
          text="We couldn't find what you were looking for."
          style={{
            fontWeight: 700,
            fontSize: '24px',
            lineHeight: '28px',
            letterSpacing: '-0.02em',
          }}
          key={getKey(report, '57_106')}
          data-d={getKey(report, '57_106')}
        />
        <Box tag="div" key={getKey(report, '57_156')}>
          <TextBody2
            text="Please reach out to "
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '20px',
            }}
            tag="span"
            key={getKey(report, '57_142')}
            data-d={getKey(report, '57_142')}
          />
          <LinkMail
            text="mysuccessteam@extern.com"
            mail="mysuccessteam@extern.com"
            key={getKey(report, '57_161')}
            data-d={getKey(report, '57_161')}
          />
          <TextBody2
            text=" for assistance."
            style={{
              fontWeight: 500,
              fontSize: '16px',
              lineHeight: '20px',
            }}
            tag="span"
            key={getKey(report, '57_157')}
            data-d={getKey(report, '57_157')}
          />
        </Box>
      </Box>
      <Box tag="div" className={styles.clt_57_96} key={getKey(report, '57_96')}>
        <ParagonPoweredBy
          key={getKey(report, '57_95')}
          data-d={getKey(report, '57_95')}
        />
      </Box>
    </Box>
  );
});

OneOnOne404.displayName = 'OneOnOne.404';

export default OneOnOne404;
