/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import TextTitleH3 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Title.H3';
import TextTitleH1 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Title.H1';
import ParagonPoweredBy from '../PoweredByParagonOne';
import styles from './styles.module.scss';

const OneOneSchedulerSideSection = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '21_97',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      background={props?.background}
      className={b.cx([styles.clt_23_47, props.className])}
      ref={ref}
      key={getKey(report, '23_47')}
    >
      <Box tag="div" className={styles.clt_17_64} key={getKey(report, '17_64')}>
        <TextTitleH3
          text="1:1 Scheduling"
          style={{
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '20px',
            letterSpacing: '-0.05em',
          }}
          key={getKey(report, '17_86')}
          data-d={getKey(report, '17_86')}
        />
        <Box
          tag="div"
          className={styles.clt_17_120}
          key={getKey(report, '17_120')}
        >
          <TextTitleH1
            text={props?.title}
            key={getKey(report, '17_119')}
            data-d={getKey(report, '17_119')}
          />
          <Box
            tag="div"
            className={styles.clt_21_56}
            key={getKey(report, '21_56')}
          >
            {props?.body}
          </Box>
          <Box
            tag="div"
            className={styles.clt_21_83}
            key={getKey(report, '21_83')}
          >
            {props?.afterBody}
          </Box>
        </Box>
        <Box
          tag="div"
          className={styles.clt_72_84}
          key={getKey(report, '72_84')}
        >
          <ParagonPoweredBy
            key={getKey(report, '17_87')}
            data-d={getKey(report, '17_87')}
          />
        </Box>
      </Box>
    </Box>
  );
});

OneOneSchedulerSideSection.displayName = 'OneOneScheduler.SideSection';

export default OneOneSchedulerSideSection;
