/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import UseState from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseState';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import textFn from './code/text/textFn.js';
import UseEffect from '../UseEffect';
import funcFn from './code/useEffect/funcFn.js';
import observerFn from './code/useEffect/observerFn.js';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import LoadingDefault from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Loading.Default';
import conditionFn from './code/if/conditionFn.js';
import Fieldset from '../Fieldset';
import Legend from '../Legend';
import TextBody1 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Body1';
import textFn1 from './code/textBody1/textFn-1.js';
import Icon from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Icon';
import TextTitleH3 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Title.H3';
import Calendar from '@clutch-marketplace/paragonone.calendar/dist/cjs/components/Calendar';
import startDateFn from './code/calendar/startDateFn.js';
import companyFn from './code/calendar/companyFn.js';
import CalendarLabel from '../Calendar.Label';
import Button from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Button';
import disabledFn from './code/button/disabledFn.js';
import onClickFn from './code/button/onClickFn.js';
import initialValueFn from './code/usestate13/initialValueFn.js';
import styles from './styles.module.scss';

const OneOnOneCalendarArea = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '55_106',
    propsArg,
    vars,
    variants
  );

  return (
    <UseState
      initialValue={b.tryCatch(initialValueFn, props, vars)}
      className={props.className}
      ref={ref}
      key={getKey(report, '30_11')}
    >
      {(
        (prevReport) =>
        (...args) => {
          const [report, vars] = b.getReport(
            '30_11',
            'children',
            prevReport,
            'usestate13',
            args
          );

          return [
            getKey(report, '55_198', null, '__CLUTCH_IF__') && false ? (
              <Text
                text={b.tryCatch(textFn, props, vars)}
                tag="span"
                key={getKey(report, 'v200_1805')}
              />
            ) : null,
            <UseEffect
              func={b.tryCatch(funcFn, props, vars)}
              observer={b.tryCatch(observerFn, props, vars)}
              key={getKey(report, '55_134')}
            />,
            getKey(report, '56_56', null, '__CLUTCH_IF__') &&
            props?.preAvailableTimes?.isLoading ? (
              <Box
                tag="div"
                className={styles.clt_v200_1817}
                key={getKey(report, 'v200_1817')}
              >
                <LoadingDefault
                  lineWidth="8px"
                  size="32px"
                  color="#1079FF"
                  key={getKey(report, '56_57')}
                  data-d={getKey(report, '56_57')}
                />
              </Box>
            ) : null,
            getKey(report, '56_95', null, '__CLUTCH_IF__') &&
            b.tryCatch(conditionFn, props, vars) ? (
              <Box tag="div" key={getKey(report, 'v200_2517')}>
                <Fieldset
                  className={styles.clt_31_174}
                  key={getKey(report, '31_174')}
                >
                  <Legend
                    className={styles.clt_31_191}
                    key={getKey(report, '31_191')}
                  >
                    <Box
                      tag="div"
                      className={styles.clt_31_259}
                      key={getKey(report, '31_259')}
                    >
                      <TextBody1
                        text={b.tryCatch(textFn1, props, vars)}
                        style={{
                          fontWeight: 600,
                        }}
                        tag="span"
                        key={getKey(report, '31_261')}
                        data-d={getKey(report, '31_261')}
                      />
                      <Icon
                        name="outlined/calendar"
                        className={styles.clt_32_0}
                        key={getKey(report, '32_0')}
                        data-d={getKey(report, '32_0')}
                      />
                    </Box>
                  </Legend>
                  <Box
                    tag="div"
                    className={styles.clt_31_3}
                    key={getKey(report, '31_3')}
                  >
                    <Box
                      tag="div"
                      className={styles.clt_31_26}
                      key={getKey(report, '31_26')}
                    >
                      <Box
                        tag="div"
                        className={styles.clt_54_52}
                        key={getKey(report, '54_52')}
                      >
                        <TextTitleH3
                          text="Select your availability below"
                          key={getKey(report, '31_13')}
                          data-d={getKey(report, '31_13')}
                        />
                      </Box>
                      <Box
                        tag="div"
                        className={styles.clt_72_96}
                        key={getKey(report, '72_96')}
                      >
                        <Calendar
                          startDate={b.tryCatch(startDateFn, props, vars)}
                          variant="company"
                          company={{
                            selectedDateTimes: vars?.usestate13?.state,
                            onChangeTimesSelection: b.tryCatch(
                              companyFn,
                              props,
                              vars
                            ),
                          }}
                          key={getKey(report, '30_0')}
                          data-d={getKey(report, '30_0')}
                        />
                      </Box>
                      <Box
                        tag="div"
                        className={styles.clt_31_60}
                        key={getKey(report, '31_60')}
                      >
                        <CalendarLabel
                          color="#EE4A4A"
                          text="Scheduled"
                          key={getKey(report, '31_146')}
                          data-d={getKey(report, '31_146')}
                        />
                        <CalendarLabel
                          color="#0135FF"
                          text="Available"
                          key={getKey(report, '31_132')}
                          data-d={getKey(report, '31_132')}
                        />
                      </Box>
                    </Box>
                  </Box>
                </Fieldset>
                <Box
                  tag="div"
                  className={styles.clt_32_23}
                  key={getKey(report, '32_23')}
                >
                  <UseState initialValue={false} key={getKey(report, '58_43')}>
                    {(
                      (prevReport) =>
                      (...args) => {
                        const [report, vars] = b.getReport(
                          '58_43',
                          'children',
                          prevReport,
                          'usestate14',
                          args
                        );

                        return (
                          <Button
                            text="Update"
                            disabled={b.tryCatch(disabledFn, props, vars)}
                            onClick={b.tryCatch(onClickFn, props, vars)}
                            variant="info"
                            loading={vars?.usestate14?.state}
                            key={getKey(report, '36_7')}
                            data-d={getKey(report, '36_7')}
                          />
                        );
                      }
                    )(report)}
                  </UseState>
                </Box>
              </Box>
            ) : null,
          ];
        }
      )(report)}
    </UseState>
  );
});

OneOnOneCalendarArea.displayName = 'OneOnOne.CalendarArea';

export default OneOnOneCalendarArea;
