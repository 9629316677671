/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import conditionFn from './code/if/conditionFn.js';
import Fragment from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/Fragment';
import Square from '../Square';
import conditionFn1 from './code/if/conditionFn-1.js';
import styles from './styles.module.scss';

const OneOneSchedulerBackground = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '22_255',
    propsArg,
    vars,
    variants
  );

  return (
    <>
      <Box
        tag="div"
        className={b.cx([styles.clt_22_180, props.className])}
        ref={ref}
        key={getKey(report, '22_180')}
      >
        {getKey(report, '23_5', null, '__CLUTCH_IF__') &&
        b.tryCatch(conditionFn, props, vars) ? (
          <Fragment key={getKey(report, 'v200_2509')}>
            <Box
              tag="div"
              className={styles.clt_22_107}
              key={getKey(report, '22_107')}
            >
              <Square
                size="60px"
                className={styles.clt_22_55}
                key={getKey(report, '22_55')}
                data-d={getKey(report, '22_55')}
              />
              <Square
                size="60px"
                className={styles.clt_22_96}
                key={getKey(report, '22_96')}
                data-d={getKey(report, '22_96')}
              />
              <Square
                size="40px"
                className={styles.clt_22_97}
                key={getKey(report, '22_97')}
                data-d={getKey(report, '22_97')}
              />
            </Box>
            <Box
              tag="div"
              className={styles.clt_22_281}
              key={getKey(report, '22_281')}
            >
              <Square
                size="60px"
                className={styles.clt_22_282}
                key={getKey(report, '22_282')}
                data-d={getKey(report, '22_282')}
              />
              <Square
                size="80px"
                className={styles.clt_22_283}
                key={getKey(report, '22_283')}
                data-d={getKey(report, '22_283')}
              />
              <Square
                size="40px"
                className={styles.clt_22_284}
                key={getKey(report, '22_284')}
                data-d={getKey(report, '22_284')}
              />
            </Box>
          </Fragment>
        ) : null}
        {getKey(report, '23_6', null, '__CLUTCH_IF__') &&
        b.tryCatch(conditionFn1, props, vars) ? (
          <Fragment key={getKey(report, 'v200_2540')}>
            <Box
              tag="div"
              className={styles.clt_23_7}
              key={getKey(report, '23_7')}
            >
              <Square
                size="40px"
                className={styles.clt_23_10}
                key={getKey(report, '23_10')}
                data-d={getKey(report, '23_10')}
              />
              <Square
                size="80px"
                className={styles.clt_23_9}
                key={getKey(report, '23_9')}
                data-d={getKey(report, '23_9')}
              />
              <Square
                size="60px"
                className={styles.clt_23_8}
                key={getKey(report, '23_8')}
                data-d={getKey(report, '23_8')}
              />
            </Box>
            <Box
              tag="div"
              className={styles.clt_23_11}
              key={getKey(report, '23_11')}
            >
              <Square
                size="40px"
                className={styles.clt_23_14}
                key={getKey(report, '23_14')}
                data-d={getKey(report, '23_14')}
              />
              <Square
                size="60px"
                className={styles.clt_23_13}
                key={getKey(report, '23_13')}
                data-d={getKey(report, '23_13')}
              />
              <Square
                size="80px"
                className={styles.clt_23_12}
                key={getKey(report, '23_12')}
                data-d={getKey(report, '23_12')}
              />
            </Box>
          </Fragment>
        ) : null}
      </Box>
      <Box tag="div" className={styles.clt_77_78} key={getKey(report, '77_78')}>
        <Box
          tag="div"
          className={styles.clt_77_116}
          key={getKey(report, '77_116')}
        >
          <Square
            size="30px"
            className={styles.clt_77_119}
            key={getKey(report, '77_119')}
            data-d={getKey(report, '77_119')}
          />
          <Square
            size="30px"
            className={styles.clt_77_118}
            key={getKey(report, '77_118')}
            data-d={getKey(report, '77_118')}
          />
          <Square
            size="20px"
            className={styles.clt_77_117}
            key={getKey(report, '77_117')}
            data-d={getKey(report, '77_117')}
          />
        </Box>
      </Box>
    </>
  );
});

OneOneSchedulerBackground.displayName = 'OneOneScheduler.Background';

export default OneOneSchedulerBackground;
