import React from 'react';

export default function Legend({ children, className, ...props }) {
  return (
    <legend className={className}>
      {children
        ? typeof children === 'function'
          ? children()
          : children
        : null}
    </legend>
  );
}
