import axios from 'axios';
import _useSWR from 'swr';
import { TokenError, ApiError } from './errors';

export default class Api {
  static get url() {
    return process.env.REACT_APP_APIURL || 'https://apidev.paragonone.com';
  }

  static get token() {
    return window.context.auth && window.context.auth.token;
  }

  static setToken(token) {
    window.context.auth
      ? (window.context.auth.token = token)
      : (window.context.auth = { token });
  }

  static get defaultHeaders() {
    const headers = {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${this.token}`,
    };

    this.setHeadersImpersonation(headers);

    return headers;
  }

  static checkToken(token) {
    if (token && window?.context?.auth?.isTokenExpired) {
      throw new TokenError();
    }
  }

  static async get(path, options = {}) {
    const headers = { ...this.defaultHeaders, ...options.headers };

    this.checkToken(headers.Authorization);
    try {
      const response = await axios.get(`${this.url}${path}`, {
        ...options,
        headers,
      });
      return response;
    } catch (error) {
      throw new ApiError('', {
        path,
        options,
        headers,
        error,
        method: 'GET',
        url: this.url,
      });
    }
  }

  static async post(path, data, options = {}) {
    const headers = { ...this.defaultHeaders, ...options.headers };

    this.checkToken(headers.Authorization);
    try {
      const response = await axios.post(`${this.url}${path}`, data, {
        ...options,
        headers,
      });
      return response;
    } catch (error) {
      throw new ApiError('', {
        path,
        data,
        options,
        headers,
        error,
        method: 'POST',
        url: this.url,
      });
    }
  }

  static async put(path, data, options = {}) {
    const headers = { ...this.defaultHeaders, ...options.headers };

    this.checkToken(headers.Authorization);
    try {
      const response = await axios.put(`${this.url}${path}`, data, {
        ...options,
        headers,
      });
      return response;
    } catch (error) {
      throw new ApiError('', {
        path,
        options,
        headers,
        data,
        error,
        method: 'PUT',
        url: this.url,
      });
    }
  }

  static async patch(path, data, options = {}) {
    const headers = { ...this.defaultHeaders, ...options.headers };

    this.checkToken(headers.Authorization);
    try {
      const response = await axios.patch(`${this.url}${path}`, data, {
        ...options,
        headers,
      });
      return response;
    } catch (error) {
      throw new ApiError('', {
        path,
        options,
        headers,
        data,
        error,
        method: 'PATCH',
        url: this.url,
      });
    }
  }

  static async delete(path, options = {}) {
    const headers = { ...this.defaultHeaders, ...options.headers };

    this.checkToken(headers.Authorization);

    try {
      const response = await axios.delete(`${this.url}${path}`, {
        ...options,
        headers,
      });

      return response;
    } catch (error) {
      throw new ApiError('', {
        path,
        options,
        headers,
        error,
        method: 'DELETE',
        url: this.url,
      });
    }
  }

  static setHeadersImpersonation(headers) {
    if (localStorage.getItem('impersonating')) {
      headers.Impersonating = true;
    }
  }

  static async swrFetcher(path) {
    const response = await this.get(path);
    return response.data;
  }

  static useSWR(key, options = {}) {
    const { data, error, mutate, isValidating } = _useSWR(
      key,
      this.swrFetcher.bind(this),
      options
    );

    return {
      data: error ? null : data,
      isLoading: !error && data === undefined,
      isError: error,
      mutate,
      isValidating,
    };
  }
}
