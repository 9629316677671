/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import ParagonPoweredBy from '../PoweredByParagonOne';
import OneOneSchedulerBackground from '../OneOneScheduler.Background';
import OneOneSchedulerSideSection from '../OneOneScheduler.SideSection';
import titleFn from './code/oneOneSchedulerSideSection/titleFn.js';
import TextBody2 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Body2';
import LinkMail from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Link.Mail';
import styles from './styles.module.scss';

const OneOnOneThankYouPage = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '40_160',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_24_99, props.className])}
      ref={ref}
      key={getKey(report, '24_99')}
    >
      <Box
        tag="div"
        className={styles.clt_77_176}
        key={getKey(report, '77_176')}
      >
        <ParagonPoweredBy
          key={getKey(report, '77_190')}
          data-d={getKey(report, '77_190')}
        />
      </Box>
      <Box
        tag="div"
        className={styles.clt_24_106}
        key={getKey(report, '24_106')}
      />
      <Box
        tag="div"
        className={styles.clt_24_100}
        key={getKey(report, '24_100')}
      >
        <OneOneSchedulerBackground
          variant="v2"
          key={getKey(report, '24_153')}
          data-d={getKey(report, '24_153')}
        />
        <Box
          tag="div"
          className={styles.clt_24_143}
          key={getKey(report, '24_143')}
        >
          <OneOneSchedulerSideSection
            title={b.tryCatch(titleFn, props, vars)}
            body={[
              <TextBody2
                text="You’ll be notified via email and receive a calendar invite when we pair you with a student."
                style={{
                  textAlign: 'justify',
                }}
                tag="p"
                key={getKey(report, '24_175')}
                data-d={getKey(report, '24_175')}
              />,
              <Box tag="div" key={getKey(report, '24_185')}>
                <TextBody2
                  text="If you have any questions, or need to reschedule a meeting, please email "
                  style={{
                    textAlign: 'justify',
                    display: 'inline',
                  }}
                  tag="p"
                  key={getKey(report, '24_176')}
                  data-d={getKey(report, '24_176')}
                />
                <LinkMail
                  text="mike@extern.com"
                  mail="mike@extern.com"
                  className={styles.clt_24_182}
                  key={getKey(report, '24_182')}
                  data-d={getKey(report, '24_182')}
                />
              </Box>,
            ]}
            key={getKey(report, '24_101')}
            data-d={getKey(report, '24_101')}
          />
        </Box>
      </Box>
    </Box>
  );
});

OneOnOneThankYouPage.displayName = 'OneOnOne.ThankYouPage';

export default OneOnOneThankYouPage;
