/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import UseState from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseState';
import conditionFn from './code/if/conditionFn.js';
import OneOnOneHelloPage from '../OneOnOne.HelloPage';
import onUpdateCalendarFn from './code/oneOnOneHelloPage/onUpdateCalendarFn.js';
import conditionFn1 from './code/if/conditionFn-1.js';
import OneOnOneThankYouPage from '../OneOnOne.ThankYouPage';
import OneOnOne404 from '../OneOnOne.404';
import PageLoading from '../PageLoading';
import initialValueFn from './code/usestate15/initialValueFn.js';

const OneOnOneApp = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '42_24',
    propsArg,
    vars,
    variants
  );

  return (
    <UseState
      initialValue={b.tryCatch(initialValueFn, props, vars)}
      className={props.className}
      ref={ref}
      key={getKey(report, '42_16')}
    >
      {(
        (prevReport) =>
        (...args) => {
          const [report, vars] = b.getReport(
            '42_16',
            'children',
            prevReport,
            'usestate15',
            args
          );

          return [
            getKey(report, '42_4', null, '__CLUTCH_IF__') &&
            b.tryCatch(conditionFn, props, vars) ? (
              <OneOnOneHelloPage
                user={props?.userData?.user}
                onUpdateCalendar={b.tryCatch(onUpdateCalendarFn, props, vars)}
                key={getKey(report, 'v200_2532')}
                data-d={getKey(report, 'v200_2532')}
              />
            ) : null,
            getKey(report, '42_14', null, '__CLUTCH_IF__') &&
            b.tryCatch(conditionFn1, props, vars) ? (
              <OneOnOneThankYouPage
                user={props?.userData?.user}
                key={getKey(report, 'v200_2513')}
                data-d={getKey(report, 'v200_2513')}
              />
            ) : null,
            getKey(report, '51_33', null, '__CLUTCH_IF__') &&
            props?.userData?.isError ? (
              <OneOnOne404
                key={getKey(report, 'v200_1779')}
                data-d={getKey(report, 'v200_1779')}
              />
            ) : null,
            getKey(report, '55_96', null, '__CLUTCH_IF__') &&
            props?.userData?.isLoading ? (
              <PageLoading
                key={getKey(report, 'v200_1800')}
                data-d={getKey(report, 'v200_1800')}
              />
            ) : null,
          ];
        }
      )(report)}
    </UseState>
  );
});

OneOnOneApp.displayName = 'OneOnOne.App';

export default OneOnOneApp;
