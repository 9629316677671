import React from 'react';

export default function Fieldset({ children, className, ...props }) {
  return (
    <fieldset className={className}>
      {children
        ? typeof children === 'function'
          ? children()
          : children
        : null}
    </fieldset>
  );
}
