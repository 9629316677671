import CalendarService from '../../../../services/Calendar';

const prevBindFn =
  (availableDateTimes, { onSuccess, before } = {}) =>
  async () => {
    try {
      if (before) before();

      await CalendarService.updateDateTimes(availableDateTimes);

      if (onSuccess) onSuccess();
    } catch (error) {
      console.error(error);
    }
  };

export default (props, vars) =>
  prevBindFn(
    vars?.['usestate13']?.['state'].map(({ dateTime }) => dateTime),
    {
      onSuccess: props?.onUpdate,
      before: () => {
        vars?.usestate14?.setState?.(true);
      },
    }
  );
