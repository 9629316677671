/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Helmet from '@clutch-marketplace/helmet/dist/cjs/components/Helmet';
import Favicon from '@clutch-marketplace/University-of-Houston---Mobile-App-Development-Fall-2021.University-of-Houston---Mobile-App-Development-Fall-2021/dist/cjs/components/Favicon';
import HelmetMeta from '@clutch-marketplace/helmet/dist/cjs/components/HelmetMeta';
import BrowserRouter from '@clutch-marketplace/react-router/dist/cjs/components/BrowserRouter';
import Switch from '@clutch-marketplace/react-router/dist/cjs/components/Switch';
import Route from '@clutch-marketplace/react-router/dist/cjs/components/Route';
import UseParams from '@clutch-marketplace/react-router/dist/cjs/components/UseParams';
import UseState from '@clutch-marketplace/clutch.clutch-react-toolkit/dist/cjs/components/UseState';
import UseDidMount from '@clutch-marketplace/react-hooks/dist/cjs/components/UseDidMount';
import UseUser from '../UseUser';
import UseEffect from '../UseEffect';
import funcFn from './code/useEffect/funcFn.js';
import observerFn from './code/useEffect/observerFn.js';
import OneOnOneApp from '../OneOnOne.App';
import callbackFn from './code/useDidMount/callbackFn.js';
import initialValueFn from './code/usestateisauthtokenset/initialValueFn.js';
import OneOnOne404 from '../OneOnOne.404';

const CompanyPlatform = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '40_43',
    propsArg,
    vars,
    variants
  );

  return (
    <>
      <Helmet
        titleTemplate=""
        defaultTitle="Company Platform"
        lang="en"
        className={props.className}
        ref={ref}
        key={getKey(report, '64_7')}
      />
      <Favicon
        faviconImageURI="https://cdn.paragonone.com/assets/images/extern-favicon.png"
        key={getKey(report, '64_20')}
        data-d={getKey(report, '64_20')}
      />
      <HelmetMeta
        content="width=device-width, initial-scale=1.0"
        name="viewport"
        key={getKey(report, '72_82')}
      />
      <BrowserRouter key={getKey(report, '40_51')}>
        <Switch key={getKey(report, '40_53')}>
          <Route
            path="/meeting-scheduler/:token"
            exact
            render={(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '40_54',
                  'render',
                  prevReport,
                  'route',
                  args
                );

                return (
                  <UseParams
                    key={getKey(report, '42_34')}
                    data-d={getKey(report, '42_34')}
                  >
                    {(
                      (prevReport) =>
                      (...args) => {
                        const [report, vars] = b.getReport(
                          '42_34',
                          'children',
                          prevReport,
                          'defaultUseParams1',
                          args
                        );

                        return (
                          <UseState
                            initialValue={b.tryCatch(
                              initialValueFn,
                              props,
                              vars
                            )}
                            key={getKey(report, '49_58')}
                          >
                            {(
                              (prevReport) =>
                              (...args) => {
                                const [report, vars] = b.getReport(
                                  '49_58',
                                  'children',
                                  prevReport,
                                  'usestateisauthtokenset',
                                  args
                                );

                                return (
                                  <UseDidMount
                                    callback={b.tryCatch(
                                      callbackFn,
                                      props,
                                      vars
                                    )}
                                    key={getKey(report, '51_16')}
                                    data-d={getKey(report, '51_16')}
                                  >
                                    {(
                                      (prevReport) =>
                                      (...args) => {
                                        const [report, vars] = b.getReport(
                                          '51_16',
                                          'children',
                                          prevReport,
                                          'useDidMount',
                                          args
                                        );

                                        return (
                                          <UseUser
                                            shouldFetch={
                                              vars?.usestateisauthtokenset
                                                ?.state
                                            }
                                            key={getKey(report, '64_51')}
                                          >
                                            {(
                                              (prevReport) =>
                                              (...args) => {
                                                const [report, vars] =
                                                  b.getReport(
                                                    '64_51',
                                                    'children',
                                                    prevReport,
                                                    'useuser1',
                                                    args
                                                  );

                                                return [
                                                  <UseEffect
                                                    func={b.tryCatch(
                                                      funcFn,
                                                      props,
                                                      vars
                                                    )}
                                                    observer={b.tryCatch(
                                                      observerFn,
                                                      props,
                                                      vars
                                                    )}
                                                    key={getKey(
                                                      report,
                                                      '65_39'
                                                    )}
                                                  />,
                                                  <OneOnOneApp
                                                    userData={vars?.useuser1}
                                                    key={getKey(
                                                      report,
                                                      '42_27'
                                                    )}
                                                    data-d={getKey(
                                                      report,
                                                      '42_27'
                                                    )}
                                                  />,
                                                ];
                                              }
                                            )(report)}
                                          </UseUser>
                                        );
                                      }
                                    )(report)}
                                  </UseDidMount>
                                );
                              }
                            )(report)}
                          </UseState>
                        );
                      }
                    )(report)}
                  </UseParams>
                );
              }
            )(report)}
            key={getKey(report, '40_54')}
          />
          <Route
            render={(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '40_60',
                  'render',
                  prevReport,
                  'route',
                  args
                );

                return (
                  <OneOnOne404
                    key={getKey(report, '42_12')}
                    data-d={getKey(report, '42_12')}
                  />
                );
              }
            )(report)}
            key={getKey(report, '40_60')}
          />
        </Switch>
      </BrowserRouter>
    </>
  );
});

CompanyPlatform.displayName = 'CompanyPlatform';

export default CompanyPlatform;
