/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import TextBody1 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Body1';
import styles from './styles.module.scss';

const CalendarLabel = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '31_129',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_31_74, props.className])}
      ref={ref}
      key={getKey(report, '31_74')}
    >
      <Box
        style={{
          backgroundColor: props?.color,
        }}
        tag="div"
        className={styles.clt_31_75}
        key={getKey(report, '31_75')}
      />
      <TextBody1
        text={props?.text}
        style={{
          fontWeight: 600,
        }}
        tag="span"
        key={getKey(report, '31_76')}
        data-d={getKey(report, '31_76')}
      />
    </Box>
  );
});

CalendarLabel.displayName = 'Calendar.Label';

export default CalendarLabel;
