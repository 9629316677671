import * as Sentry from '@sentry/react';

const prevBindFn = (user) => () => {
  user && Sentry.setUser(user);
};

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(vars?.['useuser1']?.['user'])
    : prevBindFn;
