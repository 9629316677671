export const ErrorsCode = {
  SERVICE: 1000,
  API: 2000,
  TOKEN: 3000,
};

export class ApiError extends Error {
  constructor(message, data = {}, code = ErrorsCode.API) {
    super(message);
    this.code = code;
    this.data = data;
  }
}

export class TokenError extends Error {
  constructor() {
    super('Token expired');
    this.code = ErrorsCode.TOKEN;
  }
}

export class ServiceError extends Error {
  constructor(message, error, data = {}, code = ErrorsCode.SERVICE) {
    super(message);
    this.defaultError(error, data, code);

    if (error instanceof ApiError) {
      this.apiError(error);
    }

    if (error instanceof TokenError) {
      this.tokenError(error);
    }
  }

  defaultError(error, data = {}, code = null) {
    this.message = error.message;
    this.code = code;
    this.data = data;
  }

  tokenError(tokenError) {
    this.code = tokenError.code;
  }

  apiError(apiError) {
    this.code = apiError.code;
    this.data.apiData = apiError.data;
  }
}
