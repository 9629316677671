/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import Image from '@clutch-marketplace/web-elements/dist/cjs/components/Image';
import Text from '@clutch-marketplace/web-elements/dist/cjs/components/Text';
import styles from './styles.module.scss';

const PoweredByParagonOne = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '13_86',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_13_3, props.className])}
      ref={ref}
      key={getKey(report, '13_3')}
    >
      <Image
        src="https://cdn.paragonone.com/assets/images/extern-logo-transparent.png"
        className={styles.clt_13_4}
        key={getKey(report, '13_4')}
      />
      <Text
        text="Powered by Extern"
        tag="span"
        className={styles.clt_13_15}
        key={getKey(report, '13_15')}
      />
    </Box>
  );
});

PoweredByParagonOne.displayName = 'PoweredByParagonOne';

export default PoweredByParagonOne;
