/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import LoadingDefault from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Loading.Default';
import styles from './styles.module.scss';

const PageLoading = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '55_93',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_55_39, props.className])}
      ref={ref}
      key={getKey(report, '55_39')}
    >
      <LoadingDefault
        lineWidth="8px"
        size="32px"
        color="#1079FF"
        key={getKey(report, '55_40')}
        data-d={getKey(report, '55_40')}
      />
    </Box>
  );
});

PageLoading.displayName = 'PageLoading';

export default PageLoading;
