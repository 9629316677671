import Api from '../../utils/api';

export default class CalendarService extends Api {
  static async updateDateTimes(availableDateTimes) {
    const response = await this.post('/v2/meeting-scheduler/available-times', {
      available_times: availableDateTimes,
    });

    return response.data;
  }
}
