import useSWR from 'swr';
import User from '../../domain/User';

export default ({ children, options = {}, shouldFetch = true }) => {
  const { data, error, mutate, isValidating } = useSWR(
    shouldFetch ? `/v2/meeting-scheduler/auth/` : null,
    options
  );

  return children
    ? typeof children === 'function'
      ? children({
          user: !error && data ? new User(data) : null,
          isLoading: !error && !data,
          isError: error,
          mutate,
          isValidating,
        })
      : children
    : null;
};
