/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import OneOneSchedulerBackground from '../OneOneScheduler.Background';
import OneOneSchedulerSideSection from '../OneOneScheduler.SideSection';
import Label from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Label';
import TextBody2 from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Text.Body2';
import titleFn from './code/oneOneSchedulerSideSection/titleFn.js';
import UseAvailableTimes from '../UseAvailableTimes';
import OneOnOneCalendarArea from '../OneOnOne.CalendarArea';
import styles from './styles.module.scss';

const OneOnOneHelloPage = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '40_154',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      tag="div"
      className={b.cx([styles.clt_24_300, props.className])}
      ref={ref}
      key={getKey(report, '24_300')}
    >
      <Box tag="div" className={styles.clt_24_1} key={getKey(report, '24_1')}>
        <Box
          tag="div"
          className={styles.clt_24_34}
          key={getKey(report, '24_34')}
        >
          <OneOneSchedulerBackground
            variant="v1"
            key={getKey(report, '24_127')}
            data-d={getKey(report, '24_127')}
          />
          <Box
            tag="div"
            className={styles.clt_24_132}
            key={getKey(report, '24_132')}
          >
            <OneOneSchedulerSideSection
              afterBody={
                <Box
                  tag="div"
                  className={styles.clt_24_30}
                  key={getKey(report, '24_30')}
                >
                  <Label
                    text="You can select available time slots by clicking on a time."
                    iconName="outlined/pointer-click"
                    key={getKey(report, '24_31')}
                    data-d={getKey(report, '24_31')}
                  />
                  <Label
                    text="Click and drag to select multiple slots of time at once."
                    iconName="outlined/pointer-drag"
                    key={getKey(report, '24_32')}
                    data-d={getKey(report, '24_32')}
                  />
                </Box>
              }
              body={[
                <TextBody2
                  text="Please submit 30 minute time slots over the next 2 weeks on the right. We'll use these to schedule your 1:1 conversations with students."
                  style={{
                    textAlign: 'justify',
                  }}
                  tag="p"
                  key={getKey(report, '24_213')}
                  data-d={getKey(report, '24_213')}
                />,
                <TextBody2
                  text="Try to provide at least 10 time slots. Providing as many times as possible will allow us to best find compatible availability with students."
                  style={{
                    textAlign: 'justify',
                  }}
                  tag="p"
                  key={getKey(report, '24_214')}
                  data-d={getKey(report, '24_214')}
                />,
              ]}
              title={b.tryCatch(titleFn, props, vars)}
              key={getKey(report, '24_29')}
              data-d={getKey(report, '24_29')}
            />
          </Box>
        </Box>
        <Box
          tag="div"
          className={styles.clt_24_58}
          key={getKey(report, '24_58')}
        >
          <UseAvailableTimes key={getKey(report, '64_75')}>
            {(
              (prevReport) =>
              (...args) => {
                const [report, vars] = b.getReport(
                  '64_75',
                  'children',
                  prevReport,
                  'useavailabletimes1',
                  args
                );

                return (
                  <OneOnOneCalendarArea
                    preAvailableTimes={vars?.useavailabletimes1}
                    onUpdate={props?.onUpdateCalendar}
                    key={getKey(report, '55_109')}
                    data-d={getKey(report, '55_109')}
                  />
                );
              }
            )(report)}
          </UseAvailableTimes>
        </Box>
      </Box>
    </Box>
  );
});

OneOnOneHelloPage.displayName = 'OneOnOne.HelloPage';

export default OneOnOneHelloPage;
