import { isEqual } from 'lodash';

const prevBindFn = (preAvailableTimes, selectedAvailableTimes) =>
  isEqual(preAvailableTimes, selectedAvailableTimes);

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(
        props?.['preAvailableTimes']?.['availableTimes'],
        vars?.['usestate13']?.['state']
      )
    : prevBindFn;
