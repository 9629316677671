import useSWR from 'swr';
import { isWeekend } from 'date-fns';

export default ({ children, options = {}, shouldFetch = true }) => {
  const { data, error, mutate, isValidating } = useSWR(
    shouldFetch ? `/v2/meeting-scheduler/available-times/` : null,
    options
  );

  return children
    ? typeof children === 'function'
      ? children({
          availableTimes: error
            ? null
            : data?.available_times.filter(
                ({ date }) => !isWeekend(new Date(date))
              ),
          isLoading: !error && !data,
          isError: error,
          mutate,
          isValidating,
        })
      : children
    : null;
};
