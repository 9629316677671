import React from 'react';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SWRConfig } from 'swr';
import Provider from '@clutch-marketplace/paragonone.design-system-company/dist/cjs/components/Provider';
import * as b from '@clutch-creator/bridge';

import Api from './utils/api';
import { SENTRY as SENTRY_CONFIG } from './config/constants';
import CompanyPlatform from './components/CompanyPlatform';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: SENTRY_CONFIG.DSN,
    integrations: [new BrowserTracing()],
    environment: process.env.NODE_ENV,
    tracesSampleRate: 1.0,
  });
}

const App = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey] = b.useReport('67_6', propsArg, vars, variants);

  return (
    <SWRConfig
      value={{
        refreshInterval: 0,
        revalidateOnFocus: false,
        revalidateOnReconnect: false,
        shouldRetryOnError: false,
        fetcher: Api.swrFetcher.bind(Api),
      }}
    >
      <Provider>
        <CompanyPlatform
          key={getKey(report, '67_8')}
          data-d={getKey(report, '67_8')}
        />
      </Provider>
    </SWRConfig>
  );
});

App.displayName = 'Company App';

export default App;
