import { isSameMinute } from 'date-fns';

const prevBindFn =
  (setSelectedTimes) =>
  ({ added, removed }) => {
    setSelectedTimes((s) =>
      [...s, ...added.map((dateTime) => ({ dateTime }))].filter(
        ({ dateTime }) => !removed.some((dt) => isSameMinute(dateTime, dt))
      )
    );
  };

export default (props, vars) =>
  typeof prevBindFn === 'function'
    ? prevBindFn(vars?.['usestate13']?.['setState'])
    : prevBindFn;
