/* THIS FILE WAS AUTOGENERATED BY CLUTCH. DO NOT EDIT. */
import React from 'react';
import * as b from '@clutch-creator/bridge';
import Box from '@clutch-marketplace/web-elements/dist/cjs/components/Box';
import styleFn from './code/defaultbox10/styleFn.js';

const Square = React.forwardRef((propsArg, ref) => {
  const variants = {};
  const theme = b.useTheme();
  const vars = { theme };
  const [report, getKey, props] = b.useReport(
    '22_52',
    propsArg,
    vars,
    variants
  );

  return (
    <Box
      className={b.cx([props?.className, props.className])}
      style={{
        height: props?.size,
        width: props?.size,
        transform: b.tryCatch(styleFn, props, vars),
        backgroundColor: '#F0F0F0',
      }}
      tag="div"
      ref={ref}
      key={getKey(report, '22_35')}
    />
  );
});

Square.displayName = 'Square';

export default Square;
